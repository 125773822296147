<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-list-group
          v-if="data"
          flush
        >
          <b-list-group-item>Titre: <b>{{ data.title }}</b></b-list-group-item>
          <b-list-group-item>Categorie: <b>{{ data.categoryName }}</b></b-list-group-item>
          <b-list-group-item>Description: <b>{{ data.content}}</b></b-list-group-item>
          <b-list-group-item>Pièce jointe: 
            <b>
            <a
              @click.prevent="download(buildFile)"
              title="Télécharger ce fichier"
              class="text-primary"
              >
              
              Télécharger fichier 
              <b-spinner v-if="isLoading" small />
              </a
            ></b>
          </b-list-group-item>
          
          <b-list-group-item>Date de publication:
            <b>{{
              this.$coddyger.cdgDateFormat(data.createdAt, true)
            }}</b></b-list-group-item>
          <b-list-group-item>Dernière mis à jour:
            <b>{{
              this.$coddyger.cdgDateFormat(data.updatedAt, true)
            }}</b></b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col lg="12">
            <b-list-group
              v-if="data.user"
              flush
            >
              <b-list-group-item>Administrateur: <b>{{ data.user }}</b></b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import DemarcheStore from "@/store/app/demarche/"

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return  {
      isLoading: false,
      file: ''
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  computed: {
    buildFile() {
      return this.$store.state.DemarcheStore.file
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore()
  },
  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!DemarcheStore.isRegistered) {
        console.log('enterring Store')
        this.$store.registerModule('DemarcheStore', DemarcheStore)
        DemarcheStore.isRegistered = true
      }
    },
    buildStoreData() {
      this.isLoading = true
      this.$store.dispatch('DemarcheStore/fetchFile', `${this.data.path}/${this.data.slug}`).then((doc) => {
        this.isLoading = false
        const link = document.createElement('a')
        link.href = this.$coddyger.buildPdf(doc)

        let filename = this.data.title.split(' ').join('-').toLowerCase()

        link.download = `[amiv2]${filename}`
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch((error) => {
        this.isLoading = false
        console.log(error)
        this.$swal('Erreur', 'Impossible de charger le fichier', 'error')
      })
    },
    async download() {
      // Build store data
      this.buildStoreData()
    }
  },
  setup() {
    const postStatusVariantAndIcon = requestStat => {
      if (requestStat === 'pending') return { variant: 'warning', icon: 'PieChartIcon', text: 'En attente' }
      if (requestStat === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (requestStat === 'archived') return { variant: 'danger', icon: 'XIcon', text: 'Désactivé' }
      if (requestStat === 'ended') return { variant: 'info', icon: 'CheckCircleIcon', text: 'Terminé' }
      return { variant: 'primary', icon: 'XIcon' }
    }
    const buildMymetype = payload => {
      if (payload === 'document') return { icon: 'PieChartIcon', text: 'Document' }
      if (payload === 'video') return { icon: 'CheckCircleIcon', text: 'Vidéo' }
      return { icon: 'XIcon', text: 'Type inconnu'}
    }
    const buildPublishMethod = payload => {
      if (payload === 'instant') return { icon: 'PieChartIcon', text: 'Instannée' }
      if (payload === 'delayed') return { icon: 'CheckCircleIcon', text: 'Programmée' }
      return { icon: 'XIcon', text: 'Méthode inconnue'}
    }

    return {
      buildMymetype,
      buildPublishMethod,
      postStatusVariantAndIcon,
    }
  },
})
</script>
